const products = [
    {
        id:1,
        image: require("@/assets/images/products/product-1.png"),
        name: "Blue color T-shirt",
        price: 41
    },
    {
        id:2,
        image: require("@/assets/images/products/product-2.png"),
        name: "Half sleeve maroon T-shirt",
        price: 37
    },
    {
        id:3,
        image: require("@/assets/images/products/product-3.png"),
        name: "Cream color T-shirt",
        price: 38
    },
    {
        id:4,
        image: require("@/assets/images/products/product-4.png"),
        name: "Blue color T-shirt",
        price: 37
    },
    {
        id:5,
        image: require("@/assets/images/products/product-5.png"),
        name: "Half sleeve T-shirt",
        price: 37
    },
    {
        id:6,
        image: require("@/assets/images/products/product-6.png"),
        name: "Blue Hoodie for men",
        price: 45
    },
    {
        id:7,
        image: require("@/assets/images/products/product-7.png"),
        name: "Vneck green T-shirt",
        price: 38
    },
    {
        id:8,
        image: require("@/assets/images/products/product-8.png"),
        name: "Full sleeve Pink T-shirt",
        price: 45
    }
];

export { products };
