import {useBearerTokenHeaders} from "../utils/functions";
import axios from "../axios/axios-kaqadmin";

export const invoiceService = {
    fetchInvoices,
    resendInvoice
}

function fetchInvoices(payload){

    return new Promise((resolve, reject) => {
        axios.post('/invoices/get',payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}

function resendInvoice(payload){

    return new Promise((resolve, reject) => {
        axios.post('/invoices/resend',payload, useBearerTokenHeaders() )
            .then(response => {
                resolve(response.data)
            }).catch(err => resolve({status: false, message: err}));
    })

}